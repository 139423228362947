<template>
  <div class="body">
    <div
        class="flex_able margin-top46 margin-right30 line-height42 font-size30 font-color1B1B1B margin-left30">
      <span style="flex: 1">可提现余额：{{ balanceX }}X</span>
      <span @click="openExtractRecord" class="font-size30" style="color: #268DFF">记录</span>
    </div>
    <div class="margin-top42 margin-left30 flex_able">
      <div class="titleLeftLine"></div>
      <span class="font-size32 font-color1B1B1B font-weightBold margin-left10">钱包地址</span>
    </div>
    <div class="flex_able margin-top28"
         style="background:#FAFAFA;border-radius: var(--24);height: var(--112);
        margin-left: var(--30);margin-right: var(--30);padding-left: var(--30);padding-right: var(--30);">
      <div class="font-color666666 font-size28 line-height40 twoLine" style="flex:1;">{{ userAddress }}</div>
    </div>
    <div class="margin-top42 margin-left30 flex_able">
      <div class="titleLeftLine"></div>
      <span class="font-size32 font-color1B1B1B font-weightBold margin-left10">数量 (X)</span>
    </div>
    <div class="flex_able margin-top28"
         style="background:#FAFAFA;border-radius: var(--24);height: var(--112);
        margin-left: var(--30);margin-right: var(--30);padding-left: var(--30);">
      <van-field style="flex:1;margin-right: var(--30);" v-model="withdrawalNumber" type="number" placeholder="提取数量" clearable/>
      <span v-if="false" class="font-size28 font-color666666 line-height40">全部</span>
    </div>
    <div style="margin-left: var(--30);margin-top: var(--30)">
      <span style="font-size: var(--30);font-weight: 400;color: #1b1b1b;line-height: var(--42);">矿工费：{{
          serviceChargeNumber
        }}X</span>
    </div>
    <div v-if="errorMsg.length > 0" style="color:#F35757;margin-top: var(--18);"
         class="font-size26 line-height36 margin-left30">
      {{ errorMsg }}
    </div>
    <div class="flex_center" style="margin-top: var(--60)">
      <div @click="submitInfo" class="flex_center"
           :style="{'background': (isEmpty(errorMsg) && !isEmpty(withdrawalNumber)) ? '#268DFF' : '#BEDDFF'}"
           style="width: var(--690);height: var(--88);
          border-radius: var(--44);font-size: var(--30);font-weight: 400;color: #FFFFFF;">
        立即提取
      </div>
    </div>
    <div class="font-weightBold"
         style="margin-left: var(--30);margin-top: var(--84);font-size: var(--30);color: #1b1b1b;line-height: var(--42);">
      规则：
    </div>
    <!-- 条件内容 -->
    <div style="margin-left: var(--30);margin-top: var(--16);color: #666666"
         class="participation_conditions_box_content"
         v-html="activityRole">

    </div>
  </div>
</template>

<script>
//开始1
import {Dialog, Toast} from 'vant';
import {Api} from '../../../utils/net/Api';
import {Constants} from "../../../utils/constants";
import EncryptUtils from "../../../utils/EncryptUtils";
//开始2
export default {
  data() {
    //开始4
    //初始化数据
    return {
      serviceChargeNumber: 0,
      balanceX: 0,
      errorMsg: "",
      configObject: {},
      activityRole: '',
      withdrawalNumber: '',
      userAddress: '',
    }
  },
  watch: {
    //提取数量监控
    withdrawalNumber(val, newVal) {
      console.log("val" + val)
      console.log("newVal" + newVal)
      this.serviceChargeNumber = EncryptUtils.formatDecimal(this.withdrawalNumber * this.configObject.serviceChargeProportion)
      if (this.withdrawalNumber < this.configObject.minAmount) {
        console.log("提取数量不低于" +  this.withdrawalNumber < this.configObject.minAmount)
        this.errorMsg = "提取数量不低于" + this.configObject.minAmount
      } else if (this.withdrawalNumber > this.configObject.maxAmount) {
        console.log("提取数量不高于")
        this.errorMsg = "提取数量不高于" + this.configObject.maxAmount
      } else if (this.balanceX < (this.withdrawalNumber * 1 + this.serviceChargeNumber * 1)) {
        console.log("余额不足" + this.balanceX + "--" + this.configObject.serviceChargeProportion + " --" + (this.withdrawalNumber * 1 + this.serviceChargeNumber * 1))
        this.errorMsg = "余额不足"
      }else {
        this.errorMsg = ""
      }
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "提取"
    } catch (error) {
      console.log("常规错误:" + error)
    }
    this.queryWithdrawalChainConfig()
  },
  mounted() {
    window.clickRightTitle = this.clickRightTitle;
  },
  methods: {
    isEmpty(obj) {
      return typeof obj == "undefined" || obj == null || obj == "";
    },
    openExtractRecord() {
      this.$router.push({
        path: '/extractRecord',
      });
    },
    submitInfo() {
      //判断地址
      if (!this.isEmpty(this.errorMsg)) {
        return
      }
      if (this.isEmpty(this.withdrawalNumber)) {
        return
      }
      console.log("fsdsfs")
      Dialog.confirm({
        title: "确认提取",
        message: '本次提取' + this.withdrawalNumber + 'X，到' + this.userAddress + '钱包地址内，是否确认提取？',
        confirmButtonText: "确定",
        confirmButtonColor:"#ffffff",
        cancelButtonColor:"#666666",
        messageAlign:"left",
        getContainer:".body"
      }).then(() => {
        this.exchangeDo()
      }).catch(() => {
        console.log('cancel');
      })
    },
    //请求配置
    queryWithdrawalChainConfig() {
      let params = {
        assetType: Constants.X_COIN,
        fromAccountTypeReal: Constants.X_COIN
      }
      Api.queryWithdrawalChainConfig(params).then((result) => {
        if (result.code === 100) {
          this.queryPowerAccount()
          //弹窗内容
          this.userAddress = result.data?.walletAddress
          let activityRole = result.data?.config.confDes
          this.activityRole = activityRole?.replace(/\n/g, "<br>")
          this.configObject = result.data?.config
        } else {
          Toast(result.msg);
        }
      })
    },
    queryPowerAccount() {
      let params = {
        assetType: Constants.X_COIN,
      }
      let that = this;
      Api.queryPowerAccount(params).then((result) => {
        if (result.code === 100) {
          that.balanceX = result.data?.accountVo?.balance
        } else {
          Toast(result.msg);
        }
      })
    },
    exchangeDo() {
      let params = {
        walletAddress: this.userAddress,
        amount: this.withdrawalNumber,
        assetType: Constants.X_COIN,
        fromAccountTypeReal: Constants.X_COIN,
      }
      Api.addWithdrawalChainRecord(params).then((result) => {
        if (result.code === 100) {
          Toast("处理中")
          this.withdrawalNumber = ""
          this.queryWithdrawalChainConfig()
          this.openExtractRecord()
        } else {
          Toast(result.msg);
        }
      })
    },
  },
}
</script>

<style scoped>
.body {
  background: #ffffff;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/*弹窗的样式修改开始*/
/deep/ .van-dialog {
  border-radius: var(--10);
}

/deep/ .van-dialog__header {
  color: #1B1B1B;
  font-size: var(--36);
  font-weight: bold;
}

/deep/ .van-dialog__message {
  color: #333333;
  font-size: var(--30);
}

/deep/ .van-dialog__confirm{
  margin-left: var(--34);
  margin-right: var(--22);
  margin-bottom: var(--30);
  background: #268DFF;
  border-radius: var(--44);
  width:  var(--272);
  height: var(--88);
}

/deep/ .van-dialog__cancel{
  margin-left: var(--34);
  background: #EEEEEE;
  border-radius: var(--44);
  width:  var(--272);
  height: var(--88);
}
/*弹窗更改样式结束*/

/* 设置背景和padding */
::v-deep .van-cell {
  background-color: transparent;
  padding: 0;
}

/* 设置 placeholder 颜色 */
::v-deep .van-field__control::placeholder {
  color: #7E90A0;
}

/* 输入框高度 增加热区 */
::v-deep .van-field__control {
  height: var(--112);
}

/* 设置field里面的字体大小 */
::v-deep .van-field__control {
  color: #1B1B1B;
  font-size: var(--28);
  font-weight: 400;
  line-height: var(--40);
}

::v-deep .van-cell::after {
  left: 0;
  border: none;
}

::v-deep .textClass {
  color: #FEAE4F;
  font-size: var(--28);
  line-height: var(--40);
}

::v-deep .textImg {
  margin-left: var(--6);
  width: var(--14);
  height: var(--24);
  content: url("https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/e328fbdb-69ac-43c3-a043-030ce39614f1.png");
}

.participation_conditions_box_content {
  width: var(--692);
  line-height: 160%;
  font-size: var(--30);
  color: #C6C6C6;
}

.titleLeftLine {
  width: var(--8);
  height: var(--32);
  background: #026AFC;
  border-radius: var(--4);
}
</style>
